.titleWrapper {
    display: block;
    padding-bottom: 30px;

    @media (max-width: 575px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        padding-bottom: 0;
    }

}

.creditRequestTable {
    width: calc(100vw - 315px);
    
    @media (max-width: 991px) {
        width: 100%;
    }
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin: 0 11px 0 3px !important;
}