  .detailsHeader {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
  .headerLink {
    position: 'absolute'; 
    right: '20px'; 
    padding-top:5; 
    font-size: 13;
    font-weight: 400; 
    color: '#9199AA'
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #ffffff;
  }
  .list, .cards {
    font-size: 14px;
  }
  .bodyWrapper {
    padding: 18px;
    line-height: 32px;
    text-align: left;
  }
  .background {
    padding-left: 31px;
    /* height: 60px; */
    // background-image: url(/website/page1_section3_tick.svg);
    background-size: 18px;
    background-repeat: no-repeat;
    /* margin-right: 35px; */
    text-align: left;
    font-weight: 700;
    color: #000000;
    padding-right: 30px;
  }
  .itemVal {
    font-size: 15px;
    font-weight: 400;
    color: #000000;
  }
  .bottom {
    color: #F80059;
    font-size: 15px;
    padding: 12px;
  }

  .bankDetail {
    .bankImg {
      text-align: center;
    }
    .desc {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      padding: 12px;
    }
    .detailWapper {
      .detail {
        display: flex;
        color: #000000;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        padding: 2px;
      }
      .label {
        color: #000000;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        padding-right: 15px;
      }
      .value {
        color: #000000;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        padding-right: 15px;    
      }
    }
  } 

  .list {
    width: 70%;

    text-align: left;
    .number {
      font-size: 32px;
      color: #00C1D4;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 32px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .cards {
    width: 30%;
    text-align: right;
    margin-top: 8px;
    > span {
      color: #277FFF;
    }
  }
  // Table
  .draft, .active, .reject {
    text-transform: capitalize;
  }
  .orange {
    color: #FEC63D;
  }
  .green {
    color: #1DDB47;
  }
  .red {
    color: #F80059;
  }
  .tableTitle {
    padding: 30px 0 0 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.06em;
    color: #8798AD;
    text-transform: uppercase;
  }
  .goToPage {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #008AAA;
    padding-bottom: 20px;
  }
  .productDesc {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 100px;
  }
  .actionBtn {
    display: flex;
    a > img {
      height: 20px;
      // width: 16px;
    }
    .width {
      width: 10px;
    }
  }

  .ant-collapse-header {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 53px;
    color: #00ccbd !important;
    width: 97% !important;
    text-align: center !important;
    margin: 0px auto !important;
    margin-bottom: 24px !important;
    padding: 29px !important;
    border-radius: 7px !important;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    background-position: 100% 100%;
    transition: box-shadow 0.45s, transform 0.45s;
  }
  .aggrement {
    width: 75%;
    span {
      color: #9199AA;
      font-size: 13px;
      font-weight: 400;
    }
  }
  .rowbuttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: unset !important;
  }
  .collapseTit {
    color: #008AAA;
    text-align: center;
    padding-bottom: 0px;
    margin-bottom: 4px;
  }
  .subTitle {
    margin-bottom: 16px !important;
    color: #000000;
    font-weight: 400;
  }
  .detailWapper {
    padding: 12px;
  }
  .collapseWrapper {
      // max-width: 650px;
			width: 100%;
			margin: 0 auto;
			background-color: transparent !important;
      margin-bottom: 50px;
      margin-top: 50px;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
      background-position: 100% 100%;
      transition: box-shadow 0.45s, transform 0.45s;
    .ant-collapse-item-active > .ant-collapse-header {
      color: #00ccbd !important;
    }
    .ant-tabs-bar {
      text-align: center;
    }
    .ant-collapse-header {
      // font-weight: 800;
      // font-size: 36px;
      // line-height: 53px;
      // color: #2a2b2d;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
    
    .ant-collapse-item-active > .ant-collapse-header {
      color: #00ccbd !important;
    }
  }
// }
.icons {
  padding-left: 6px;
}
.bidDetaill {
  border: unset !important;
  box-shadow: none !important;

  .ant-card-bordered {
    border: unset !important;
    box-shadow: none !important;
  }
}
.cardDetaill {
  // height: 450px;
  .labelitem, .valueitem {
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 16px;
    color: #000000;
    line-height: 21px;
  }
  .valueitem {
    font-weight: 400;
  }
  .orange {
    color: #FEC63D !important;
  }
  .detailWrapper {
    display: flex;
    justify-content: space-between;
  }
  .cardTitle {
    color: #8798AD !important;
    text-transform: uppercase;
    padding-bottom: 20px;
    text-align: left !important;
  }
  .backLink {
    text-align: left !important;
    margin: 3% 0 0 0 !important;
    text-transform: uppercase;

  }

}

.tableFile {
  font-weight: 800;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .cardDetaill {
    height: 100%;
  }
  .headerLink {
    position: relative;
  }
}

.dateModalBtns button {
  width: 180px;
  margin: 0 10px;

  @media screen and (max-width: 575px) {
    margin: 5px 10px;
  }
}

.approvedBtn {
  margin: 0 10px 20px;
  min-width: 285px !important;

  @media screen and (max-width: 575px) {
    min-width: 250px !important;
    word-wrap: break-word;
    white-space: normal !important;
  }
}