$font-family-base: 'Mulish', sans-serif;
$font-family-sans-serif: 'Mulish', sans-serif;
$font-size-base: 1.125rem;
//$body-bg: #f6f9fb;
$h2-font-size: 20px;

$card-border-radius: 6px;
$card-border-color: #f0f0f0;
$card-border-width: 1px;
$card-cap-bg: #ffffff;
$card-cap-padding-y: 30px;
$card-cap-padding-x: 20px;

// scss-docs-end colors-map

$primary:       #00CCBD;
$secondary:     #008AAA;
$success:       #70E878;
$info:          #008AAA;
// $warning:       $orange;
$danger:        #F80059;
// $light:         $gray-100;
// $dark:          $gray-900;

// "highlight-color": "#277FFF",
// font-size: 18px;
// line-height: 23px;
// font-family": "'Mulish', serif",
// Dropdowns
$blue-green: #00aad1; 


$dropdown-bg: $secondary;
$dropdown-link-color: #ffffff;
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 0;
$dropdown-link-hover-color: #ffffff;
$dropdown-link-hover-bg: $blue-green;
// $dropdown-border-radius: 8px 0px 8px 8px;
$dropdown-box-shadow: 0px 4px 8px rgba(#000000, 0.3);
// $dropdown-link-active-color:        $component-active-color;
// $dropdown-link-active-bg: #006076;
$dropdown-link-active-bg: #00aad1;

$dropdown-divider-bg: rgba(#fff, 0.6);

// &:hover {
//     background-color: #00aad1;
// }
// &:focused {
//     background-color: #006076;
// }
// &:selected {
//     background-color: #006076;
// }

// Links

$link-decoration: none;
