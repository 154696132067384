/** Headings */
h2 {
  font-weight: 700 !important;
  color: #00CCBD;
  margin-bottom: 45px;
  font-size: 20px;
}

/** Card */
.card {
  box-shadow: 0 2px 16px native-rgb(0 0 0 / 8%);
  background-position: 100% 100%;
  transition: box-shadow .45s,transform .45s;

  .card-header {
    //padding: 30px 0 0 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .06em;
    color: #8798ad;
    text-transform: uppercase;
    border-bottom: none;
  }

  .card-body {
    padding: 0;
    margin-top: 0;
  }

  .button:focus:not(:focus-visible) {
    outline: none !important;
  }
}