.baseView {
  display: flex;
  padding-top: 12px;

  .ant-legacy-form-item .ant-legacy-form-item-control-wrapper {
    width: 100%;
  }

  .left {
    // min-width: 224px;
    // max-width: 448px;
    min-height: calc(100vh - 260px);
  }
  .profile-right {
    flex: 1;
    padding-left: 104px;
    .avatar_title {
      height: 22px;
      margin-bottom: 8px;
      // color: @heading-color;
      // font-size: @font-size-base;
      line-height: 22px;
    }
    .avatar {
      width: 144px;
      height: 144px;
      margin-bottom: 12px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .button_view {
      width: 144px;
      text-align: center;
    }
  }
}

.nextbutton {
	background-color: '#008AAA';
	// background-image: url("../../../../assets/button_bg.svg"); 
	border-width: 0;
	width: 192px;
	height: 50px;
	border-radius: 6px;
	color: #f6f9fb;
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
}

.confirmTitle {
  margin-top: 48px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: rgba(42,43,45,.85);
}

  .baseView {
    flex-direction: column-reverse;

    .profile-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 448px;
      padding: 20px;
      .avatar_title {
        display: none;
      }
    }
  }

