.cfa {
    &-container {
        display: flex;
        min-height: 100vh;
        background-color:#f4f6fc;
        justify-content: center;
        align-items: center;
    }

    &-content {
        padding: 1.5em 3em;
        width: 50em;
        max-height: 30em;
        background: #fff;
        text-align: center;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        &-header {
            text-align: center;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            &-small {
                font-weight: normal;
                font-size: 13px;
                margin: 0.3em 0 0.5em 0;
                line-height: 16px;
            }
        }
    }
}