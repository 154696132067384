.tableLoanOffersTable .ant-table {
    background-color: transparent;
}

.orange {
    color: #FEC63D;
  }
.green {
color: #1DDB47;
}
.red {
color: #F80059;
}