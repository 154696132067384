.ant-drawer-content-wrapper {
    width: 294px !important;
}

.ant-drawer-header {
    border-top: 1px solid #f0f0f0;
}

.customDrawer {
    .ant-drawer-title {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.13px;
        text-transform: uppercase;
        color: #B0BAC9;
    }
}
