.bt-header {
  padding: 0 5%!important;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  // height: 60px;
  // transition: background .3s,width .2s;

  @media (min-width: 992px) {
    height: 80px;
  }
}

.left {
  display: flex;
  height: 100%;
  min-width: 188px;
}

.headerLogo img {
  height: 42px;
}

.headerLogoText img {
  padding: 10px 0px 0px 10px;
  height: 32px;
}


@media only screen and (max-width: 991px) {
  .headerLogo img {
    height: 40px;
  }

  .headerLogoText img {
    padding: 8px 0px 0px 7px;
    height: 30px;
  }

  .right {
    height: 60px;
    right: 5%;
  }

  .middle {
    height: 60px;
  }
}

.menuButton button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  font-size: 14px;
  border-radius: 6px;
  color: #fff;
  background: #00CCBD;
  border-color: #00CCBD;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);

  &:hover {
    background: #23d9c7;
    border-color: #23d9c7;
  }

  @media (min-width: 992px) {
    display: none;
  }
}