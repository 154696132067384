.notificationsList {
    min-height: 80vh;
    overflow: auto;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .item {
      // padding-right: 24px;
      // padding-left: 24px;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;

      &:not(:first-child) {
        padding: 24px 0;
      }
  
      .meta {
        width: 100%;
      }
  
      .avatar {
        margin-top: 4px;
        // background: @component-background;
      }
      .iconElement {
        font-size: 32px;
      }
  
      &.read {
        opacity: 0.4;
      }
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        // background: @primary-1;
        // background: #00CCBD;
      }
      .title {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.13px;
        text-transform: uppercase;

        color: #B0BAC9;
      }
      .description {
        font-size: 15px;
        line-height: 22px;
        color: #2E384D;
      }
      .datetime {
        margin-top: 4px;
        font-size: 12px;
        // line-height: @line-height-base;
      }
      .extra {
        float: right;
        margin-top: -1.5px;
        margin-right: 0;
        // color: @text-color-secondary;
        font-weight: normal;
      }
    }
    .loadMore {
      padding: 8px 0;
    //   color: @primary-6;
      text-align: center;
      cursor: pointer;
      &.loadedAll {
        color: rgba(0, 0, 0, 0.25);
        cursor: unset;
      }
    }
  }
  
  .notFound {
    padding: 73px 0 88px;
    // color: @text-color-secondary;
    text-align: center;
    img {
      display: inline-block;
      height: 76px;
      margin-bottom: 16px;
    }
  }
  
  .bottomBar {
    height: 46px;
    // color: @text-color;
    line-height: 46px;
    text-align: center;
    // border-top: 1px solid @border-color-split;
    // border-radius: 0 0 @border-radius-base @border-radius-base;
    transition: all 0.3s;
    div {
      display: inline-block;
      width: 50%;
      cursor: pointer;
      transition: all 0.3s;
      user-select: none;
  
      &:only-child {
        width: 100%;
      }
      &:not(:only-child):last-child {
        // border-left: 1px solid @border-color-split;
      }
    }
}

.notificationLink {
  display: flex;
  align-items: center;
  padding-top: 8px;
  font-size: 13px;
  font-weight: 400;
  color: rgb(145, 153, 170);
}

.notificationsList {
  .ant-list-item-meta-avatar {
    // margin-right: 16px;
    align-items: center;
    justify-content: center;
    align-self: center;
    background-color: #F9FAFD;
    display: flex;
    padding: 7px 3px;
    border-radius: 50%;
  }
}

.notificationItemIcon.notificationIsRead {//notificationItemIconAnt
  svg path, path {
    stroke: rgb(145, 153, 170);
  }
}

.notificationItemIcon {
  font-size: 18px;
  background-size: 18px 18px;
  max-height: 18px;

  svg path, path {
    stroke: #008AAA;
  }
}

.notificationItemIconAnt.notificationIsRead {//notificationItemIconAnt
  svg path, path {
    fill: rgb(145, 153, 170);
  }
}

.notificationItemIconAnt {
  font-size: 18px;
  background-size: 18px 18px;
  max-height: 18px;

  svg path, path {
    fill: #008AAA;
  }
}

.notificationFilledItemIcon.notificationFilledIsRead {
  // svg {
    fill: rgb(145, 153, 170);
  // }
}

.notificationFilledItemIcon {
  font-size: 18px;
  background-size: 18px 18px;
  max-height: 18px;
  fill: #008AAA;
  // svg {
    
  // }
}