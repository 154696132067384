/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
    width: 384px;

    @media screen and (max-width: 575px) {
        width: 90vw;
    }
}
/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-right {
    @media screen and (max-width: 575px) {
        top: 10px;
        left: 10%;
    }
}

/** Classes for the displayed toast **/
.Toastify__toast {
    width: 376px;
    word-wrap: break-word;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);

    @media screen and (max-width: 575px) {
        width: 85vw;
    }
}
// .Toastify__toast--rtl {
// }
// .Toastify__toast--dark {
// }
// .Toastify__toast--default {
// }
// .Toastify__toast--info {
// }
// .Toastify__toast--success {
// }
// .Toastify__toast--warning {
// }
// .Toastify__toast--error {
// }
// .Toastify__toast-body {
//     border: 3px solid red;
// }

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
    color: rgba(42, 43, 45, 0.85);
    height: 12px;
    width: 12px;
    margin-right: 16px;
}

// .Toastify__close-button--default {
// }
// .Toastify__close-button > svg {
// }
// .Toastify__close-button:hover, .Toastify__close-button:focus {
// }

// /** Classes for the progress bar **/
// .Toastify__progress-bar {
// }
// .Toastify__progress-bar--animated {
// }
// .Toastify__progress-bar--controlled {
// }
// .Toastify__progress-bar--rtl {
// }
// .Toastify__progress-bar--default {
// }
// .Toastify__progress-bar--dark {
// }

.toast-body {
    display: flex;
    align-items: stretch;
}

.toast__icon {
    display: flex;
    align-items: center;

    svg {
        height: 24px;
        width: 24px;
        align-self: center;
    }

    &--error svg {
        fill: #f80059;
    }

    &--success svg {
        fill: #008aaa;
    }
}

.toast-text {
    height: 100%;
    margin-bottom: 4px;
    margin-left: 16px;
    flex:1;
    position:relative;
    font-size: 16px;
    color: rgba(42, 43, 45, 0.85);
    font-family: 'Mulish', serif;
    word-break: keep-all;
}