.header-avatar-btn {
    height: 40px;
    width: 40px;
    outline: none;
    display: flex;
    justify-content: center;
    font-size: 33px !important;
    padding: 7px 0;
    color: #ffffff !important;
    background-color: #008aaa !important;
    border: none;
    margin: 4px 0 4px 27px;
    border-radius: 50%;
    transition: all .2s ease-in;

    &:hover {
        background: #00aad1 !important;
    }

    &:focus {
        outline: none !important;
        outline-color: transparent !important;
        outline-style: none !important;
        outline-width: 0 !important;
    }
}

.avatar-icon {
    width: 28px;
    height: 33px;
    // background-color: white;
    overflow: hidden;
    z-index: 2;
}

.dropdown-menu {
    background-color: rgb(0, 138, 170);
    padding: 0px !important;
    border-radius: 6px;
    overflow: hidden;
    width: 300px;
    margin-top: -4px;

    .dropdown-link-icon {
        margin-right: 22px;
        font-size: 22px;
    }

    .dropdown-link {
        display: flex;
        align-items: center;
        padding: 0 10px 0 24px;
        color: #ffffff;
        height: 60px;
        text-decoration: none;
        transition: all .3s ease-in;
    }

    .dropdown-item {
        &__last {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}