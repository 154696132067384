.main {
	display: flex;
	width: 800px;
	height: 100%;
	margin: 0 auto;
	padding-top: 16px;
	padding-bottom: 16px;
	overflow: auto;
	min-height: calc(100vh - 120px);

	.leftMenu {
		width: 224px;
		border-right: 1px solid #f0f0f0;
		
		.ant-menu-inline {
			border: none;
		}
		.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
			font-weight: bold;
		}
	}

	.profileMenuList {
		min-height: 100%;
		padding-top: 16px;

		.ant-menu-item {
			font-size: 14px;
			font-weight: 400;
			height: 52px;
			line-height: 52px;
			color: rgba(42, 43, 45, 0.85);
		}

		&:not(.ant-menu-horizontal) .profileMenuItem.ant-menu-item-selected {
			background-color: #e6fff9 !important;
			color: #00CCBD !important;
		}

		.profileMenuItem.ant-menu-item::after {
			border-right: 3px solid #00ccbd;
		}
	}

	.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
	.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
	.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
	.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
	.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
	.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
	.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
	.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
	  color: #00CCBD;
	  border-bottom: 2px solid #00CCBD;
	}

	.ant-menu-item:hover, .ant-menu-submenu:hover, .ant-menu-item-active, .ant-menu-submenu-active, .ant-menu-item-open,
	.ant-menu-submenu-open, .ant-menu-item-selected, .ant-menu-submenu-selected {
		transition: border-color 0.3s, background-color 0.3s;
	}

	.ant-menu-item, .ant-menu-submenu-title {
		transition: border-color 0.3s, background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	}

	.profile-right {
		flex: 1;
		padding-top: 8px;
		padding-right: 40px;
		padding-bottom: 8px;
		padding-left: 40px;
		background-color: #ffffff;

		.profile-title {
			margin-top: 24px;
			margin-bottom: 12px;
			// color: @heading-color;
			font-weight: 700;
			font-size: 20px;
			line-height: 28px;
		}
	}
	
	.ant-list-split .ant-list-item:last-child {
		border-bottom: 1px solid blue;
	}
	.ant-list-item {
		padding-top: 14px;
		padding-bottom: 14px;
	}
}

.nextbutton {
	background-color: '#008AAA';
	border-width: 0;
	width: 192px;
	height: 50px;
	border-radius: 6px;
	color: #f6f9fb;
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
}

.ant-list-item-meta {
	.dingding {
		margin: 2px;
		padding: 6px;
		color: #fff;
		font-size: 32px;
		line-height: 32px;
		background-color: #2eabff;
		border-radius: 6px;
	}
}

.profile-title {
	margin-bottom: 12px;
    color: rgba(42, 43, 45, 0.85);
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.confrmWrapper {
	display: flex;
	min-height: calc(100vh - 120px);
}

@media screen and (max-width: 768px) {
	.main {
		width: 100%;
		flex-direction: column;
	}
}


@media screen and (max-width: 768px) {
	.main .leftMenu {
		width: 100%;
		border: none;
	}
}

@media screen and (max-width: 768px) {
	.main .profile-right {
		padding: 40px;
	}
}

@media screen and (max-width: 440px) {
	.main {
		width: 100%;
		flex-direction: column;
		.leftMenu {
			width: 100%;
			border: none;
		}
		.profile-right {
			padding: 40px;
		}
	}
}
