.masterLayout-content {
  padding: 20px;
  background-color:  #F4F6FC;
  width: 100%;
}

.roleError {
  position: fixed;
  z-index: 20000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #008aaa !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
}