.layout-leftPanel {
  flex: 0 0 234px;
  max-width: 234px;
  min-width: 200px;
  width: 234px;
  background-color: white;
  position: relative;
  min-height: calc(100vh - 80px);
  transition: all 0.3s;

  @media (max-width: 991px) {
    display: none;
  }
}

ul.leftPanel-menu {
  width: 100%;
  border-right: 1px solid #f0f0f0;
  font-feature-settings: "tnum","tnum";
  font-variant: tabular-nums;
  margin: 0;
  padding: 0;
  color: rgba(42,43,45,.85);
  font-size: 12px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  //outline: none;
  //transition: background .3s,width .3s cubic-bezier(.2,0,0,1) 0s;

  .leftPanel-menu-item {
    margin-bottom: 8px;
    //width: calc(100% + 1px);
    margin-top: 4px;
    padding: 0 16px 0 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
    font-weight: 400 !important;
    height: 52px !important;
    line-height: 52px !important;
    display: block;
    cursor: pointer;
    transition: border-color .3s, background .3s,padding .15s cubic-bezier(.645,.045,.355,1);
  }

  .leftPanel-menu-item-selected {
    background-color: #e6fff9;
    color: #008aaa !important;
    font-weight: 700 !important;
    border-right: 3px solid #00ccbd;

    &:before {
      transform: scaleY(1);
      opacity: 1;
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .leftPanel-menu-item-link {
      color: #00CCBD;
      font-weight: 700;
    }
  }

  .leftPanel-menu-item-selected path,
  .leftPanel-menu-item-selected circle {
    stroke: #00CCBD;
  }
}

.callTitle {
  font-weight: 400;
  font-size: 12px;
  color: #008AAA;
}
h2 {
  font-weight: 700;
  color: #00CCBD;
  margin-bottom: 45px;
  font-size: 20px;
}
.callImg {
  img {
    width: 76%;
  }
}

.leftPanel-menu-item-link {
  text-decoration: none;
  color: rgba(42, 43, 45, 0.85);
  font-size: 14px;
  font-weight: 400;

  &:hover {
    color: #00CCBD;
  }

}

.menuImg {
  height: 16px;
  padding-right: 12px;
  margin-top: -5px;
  color: rgba(42, 43, 45, 0.85);
}
