.notifications-table-wrapper {
    width: calc(100vw - 315px);
    
    @media (max-width: 991px) {
        width: 100%;
    }
}

.notificationsLink {
    &:hover {
        color: #008aaa;
    }
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    .notificationsLink {
        color: #fff;

        &:hover {
            color: #008aaa;
        }
    }
}