.listWrapper {
    width: 320px;

    @media (max-width: 575px) {
        max-width: 264px;
        width: 100%;
    }
}

.dateWrapper {
    width: 264px;

    @media (max-width: 575px) {
        width: 100%;
    }
}

.longListWrapper {
    @media (max-width: 767px) {
        // max-width: 264px;
        width: 100%;
    }
}

.otherTermInput {
    width: 500px !important;
    @media (max-width: 767px) {
        width: 450px !important;
    }
    @media (max-width: 575px) {
        width: 100% !important;
    }
}

.otherTermsBtn {
    min-width: 270px;
    @media (max-width: 575px) {
        min-width: 240px;
    }
}

.otherTermsBtnIcon {
    @media (max-width: 575px) {
        display: none !important;
    }
}

.amorteringList {
    label {
        height: 100%;
    }
}
