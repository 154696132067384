.companyForm .ant-form-item-label > label,
.companyForm .ant-row.ant-form-item .ant-form-item-label > label {
    font-weight: 400;
}

.add-btn {
    height: 34px;
    width: 34px;
    border-radius: 50%;

    &:focus {

    }
}

.add-btn-icon svg {
    height: 30px;
    width: 30px;
}

.inChannelOther {
    &-enter {
        opacity: 0;
        &-active {
            opacity: 1;
            transform: translateX(0);
            transition: all .3s;
        }
    }
    &-exit {
        opacity: 1;
        &-active {
            opacity: 0;
            transition: all .3s;
        }
    }

}
