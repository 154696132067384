.dashboard {

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #ffffff;
  }
  .list, .cards {
    font-size: 14px;
  }
  .bodyWrapper {
    display: flex;
    padding: 18px;
  }
  .list {
    width: 70%;

    text-align: left;
    .number {
      font-size: 32px;
      color: #00C1D4;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 32px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .cards {
    width: 30%;
    text-align: right;
    margin-top: 8px;
    > span {
      color: #277FFF;
    }
  }
  // Table
  .draft, .active, .reject {
    text-transform: capitalize;
  }
  .orange {
    color: #FEC63D;
  }
  .green {
    color: #1DDB47;
  }
  .red {
    color: #F80059;
  }
  .tableTitle {
    padding: 30px 30px 0 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.06em;
    color: #8798AD;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .goToPage {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #008AAA;
    padding-bottom: 20px;
  }
  .productDesc {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 100px;
  }
  .actionBtn {
    display: flex;
    a > img {
      height: 20px;
      // width: 16px;
    }
    .width {
      width: 10px;
    }
  }
}

.icons {
  padding-left: 6px;
}

.cardDetaill {
  height: 100%;
  .labelitem, .valueitem {
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 16px;
    color: #000000;
    line-height: 21px;
  }
  .valueitem {
    font-weight: 400;
  }
  .orange {
    color: #FEC63D !important;
  }
  .detailWrapper {
    display: flex;
    justify-content: space-between;
  }
  .cardTitle {
    color: #8798AD !important;
    text-transform: uppercase;
    padding-bottom: 20px;
    text-align: left !important;
  }
  .backLink {
    text-align: left !important;
    margin: 3% 0 0 0 !important;
    text-transform: uppercase;
  }
  .bankName {
    display: block;
    font-size: 15px;
    font-weight: 700;
    padding: 10px;
    cursor: pointer;
    transition: all .2s ease;
    color: #000000;
    &:hover {
      background-color: #00ccbd;
      color: white;
    }
  }
}

.tableFile {
  font-weight: 800;
  font-size: 14px;
}

.messageText {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 16px;
  color: #000000;
  line-height: 18px;
}

.messageText-list {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 16px;
  color: #000000;
  line-height: 18px;

  &::before {
    content: "\2022";
    color: #00CCBD;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

@media screen and (max-width: 768px) {
  .cardDetaill {
    height: 100%;
  }
}

.creditInfoCard {
  padding-left: 12px;
  // padding-right: 12px;
  margin-bottom: 24px;

  @media screen and (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.iconDelete {
  margin: 0 !important;
  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: #F80059;
  }
}

.iconBtnTable:hover .iconDelete {
  path {
    fill: #fff;
  }
}

.iconBtnTable:active .iconDelete {
  path {
    fill: #008aaa;
  }
}

.message-enter {
  opacity: 0;
}
.message-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.message-exit {
  opacity: 1;
} 
.message-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}