.tableLoanOffersTable .ant-table {
    background-color: transparent;
}

.orange {
    color: #FEC63D;
  }
.green {
color: #1DDB47;
}
.red {
color: #F80059;
}

.debtorCell {
  max-width: 300px;
}

.table-custom-filter  {
  .ant-table-filter-trigger-container {
    right: unset !important;
    left: 50%;
    transform: translateX(-50%);
  }
}