.loginLayout {
    width: 100%;
    min-height: 100%;
    background-color: #f6f9fb;
}

.loginLayout-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 80px);

    @media screen and (max-width: 991px) {
        padding: 5%;
    }
}

.login-form {
    min-height: 400px;
}

.login-box {
    width: 860px;

    @media screen and (max-width: 991px) {
        width: 100%;
    }

    .login-form {
        padding: 28px 112px;
        width: 100%;

        @media screen and (max-width: 991px) {
            flex: 1 1;
            padding: 5% 5% !important;
        }
    }

    .login-intro {
        width: 334px;
        background-color: #00ccbd;
        padding: 40px;
        background-image: url("../../assets/section_bg.svg");
    }
}

.container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.main {
    .formsection {
        flex: 1;
        padding: 28px 112px;
    }

    .registerformsection {
        flex: 1;
        padding: 20px 40px 10px 40px;
    }

    .contentsection {
        width: 334px;
        background-color: #00ccbd;
        background-image: url("../../assets/section_bg.svg");
        padding: 40px;
        z-index: 1;

        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    .icon {
        margin-left: 16px;
        //color: @text-color-secondary;
        font-size: 24px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            //color: @primary-color;
        }
    }

    .other {
        margin-top: 24px;
        line-height: 22px;
        text-align: left;

        .register {
            float: right;
        }
    }

    :global {
        .antd-pro-login-submit {
            width: 100%;
            margin-top: 24px;
        }

        .ant-result {
            padding: 0px 0px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .ant-result-subtitle {
            color: #008aaa !important;
            margin-top: 10px;
        }
    }
}

.mainmobile {
    width: 100%;
    margin: 5%;

    .formsection {
        flex: 1;
        padding: 5% 5% !important;
    }
}

.borderbutton {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #ffffff;
    width: 192px;
    height: 50px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
}

.filledbutton {
    background-color: #008aaa;
    border-width: 0;
    width: 192px;
    height: 50px;
    border-radius: 6px;
    color: #f6f9fb;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
}

.previousbutton {
    background-color: #ffffff;
    border: 1px solid #00748f;
    width: 192px;
    height: 50px;
    border-radius: 6px;
    color: #00748f;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;

    :global {
        .anticon {
            display: none;
        }
    }
}

.formsectionheading {
    font-size: 28px;
    font-weight: 700;
    font-style: normal;
    line-height: 34px;
    color: #00ccbd;
    text-align: center;
}

.formsectionsubheading {
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    line-height: 34px;
    color: #00ccbd;
    text-align: center;
}

.links {
    display: block;
    margin-bottom: 30px;
    cursor: pointer;
}

.contentsectionheading {
    font-size: 28px;
    font-weight: 700;
    font-style: normal;
    line-height: 34px;
    color: #ffffff;
    text-align: center;
}

.contentsectiontext {
    width: 216px;
    margin: 40px auto 56px auto;
}

.formsectiontext {
    color: #9199aa;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: normal;
    font-style: normal;
    line-height: 20px;
}

.logo {
    margin-bottom: 60px;
}

.iconprimary {
    color: #00ccbd !important;
}
/*
@include media-breakpoint-down(md){
  .previousbutton {
    width: 70px;
    :global {
      .anticon {
        display: inline-block;
      }
    }
    a {
      display: none;
    }
  }
  .filledbutton {
    width: 150px;
  }
}
*/

.login-form {
    &.ant-form-item-has-error {
        .ant-input-affix-wrapper {
            background-color: #f6f9fb;
            border: 1px solid #f80059 !important;
        }

        .ant-input-prefix {
            color: #f80059 !important;
        }

        .ant-input-prefix > svg > path {
            stroke: #f80059 !important;
        }
    }
}

// .login-form .ant-form-item-has-error .ant-input-affix-wrapper {
//   background-color: #f6f9fb;
//   border: 1px solid #f80059 !important;
// }

// .login-form .ant-input-prefix {
//   color: #f80059 !important;
// }

// .login-form .ant-form-item-has-error .ant-input-prefix > svg > path {
//   stroke: #f80059 !important;
// }

// .login-form .ant-form-item-explain {
//   clear: both;
//   min-height: 24px;
//   transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
//   padding-top: 2px;
// }

// .login-form .ant-form-item-explain.ant-form-item-explain-error {
//   color: #F80059;
// }

// .login-form .ant-form-item {
//   position: relative;
// }

// .login-form .ant-form-item-explain.ant-form-item-explain-error {
//   position: absolute;
//   bottom: -24px;
// }

.stepForm .ant-form-item-control {
    &.ant-form-item-has-error {
        .ant-input-affix-wrapper {
            background-color: #f6f9fb;
            border: 1px solid #f80059 !important;
        }

        .ant-input-prefix {
            color: #f80059 !important;
        }

        .ant-input-prefix > svg > path {
            stroke: #f80059 !important;
        }
    }
}

// .ant-form-item-explain.ant-form-item-explain-error {
//   color: #F80059 !important;
// }

.stepForm
    .ant-form-item-has-error
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-select
    .ant-select-selector {
    border-color: #f80059 !important;
}

.ant-form-item-has-error .ant-input-affix-wrapper {
    background-color: #f6f9fb;
    border: 1px solid #f80059 !important;
}

.ant-input-prefix {
    color: #f80059 !important;
}

.ant-form-item-has-error .ant-input-prefix > svg > path {
    stroke: #f80059 !important;
}

.ant-form-item-explain {
    clear: both;
    min-height: 24px;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    padding-top: 2px;
}

.ant-form-item-explain.ant-form-item-explain-error {
    color: #f80059 !important;
}

.ant-form-item {
    position: relative;
}

// .ant-form-item-explain.ant-form-item-explain-error {
//   position: absolute;
//   bottom: -24px;
// }

.fade-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
}
.fade-exit {
    opacity: 1;
    transform: translateX(0%);
}
.fade-exit-active {
    opacity: 0;
    transform: translateX(100%);
}
.fade-enter-active,
.fade-exit-active {
    transition: opacity 500ms, transform 500ms;
}

.loginPopup {
    &-close {
        position: absolute;
        top: 18px;
        right: 4%;
        cursor: pointer;
    }
    &-container {
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.384);
        inset: 0;
    }

    &-loader {
        position: relative;
        padding: 50px 50px 40px 50px;
        background-color: #fff;
        border-radius: 5px;
        width: 400px;
        height: 380px;
        font-size: 14px;
        &.small {
            height: unset;
        }
        .ant-spin-text {
            color: #00ccbd;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding: 30px 0 50px;
        p {
            font-weight: 700;
            margin-bottom: 15px;
        }
        &-item {
            cursor: pointer;
            padding: 5px;
            transition: all 0.3s ease;
            &:hover {
                background-color: #00ccbd;
                color: #fff;
            }
        }
    }

    &-enter {
        transform: scale(0.9);
        opacity: 0;
        &-active {
            transform: translateX(0);
            opacity: 1;
            transition: opacity 300ms, transform 300ms;
        }
    }
    &-exit {
        opacity: 1;
        &-active {
            transform: scale(0.9);
            opacity: 0;
            transition: opacity 300ms, transform 300ms;
        }
    }
}
