//--------------Button Type 8 (Bordered Light) --------

.buttonType8 {
	background-color: transparent;
	border: 2px solid #8798AD;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: #8798AD;
	min-height: 50px;
    border-radius: 6px;

	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.buttonType8:hover {
	background-color: #ffffff;
	border: 2px solid #8798AD;
	color: #8798AD;
}

.buttonType8:focus {
	border-width: 0px;
	background-color: #ffffff;
	color: #8798AD;
}

.buttonType8:disabled,
.buttonType8:disabled:hover,
.buttonType8:disabled:focus {
	background-color: #ffffff;
	border: 2px solid #8798AD;
	color: #8798AD;
}

//--------------Button Type 10 (Bordered Light) --------

.buttonType10 {
	background-color: #FFFFFF;
	border: 1px solid #BFC5D2;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: #BFC5D2;
    border-radius: 6px;
    height: 40px;

    &__link {
        
    }
}

.buttonType10:hover {
	background-color: #ffffff;
	border: 1px solid #BFC5D2;
	color: #BFC5D2;
}

.buttonType10:focus {
	border-width: 0px;
	background-color: #ffffff;
	color: #BFC5D2;
}

.buttonType10:disabled,
.buttonType10:disabled:hover,
.buttonType10:disabled:focus {
	background-color: #ffffff;
	border: 2px solid rgba(40, 122, 255, 0.15);
	color: #287AFF;
}

.actionBtn {
    display: flex;
	justify-content: center;
    width: 100%;
}

.actionBtn a {
    text-decoration: none;
}

.actionBtn button {
	width: 100%;
	display: inline-flex;
	align-items: center;
	background-color: #fff;
	border: 1px solid #008aaa;
	color: #008aaa;
	transition: color .3s, stroke .3s;
	
	svg {
	  stroke: #008aaa;
	}
}
  
.actionBtn button:hover {
	border: 1px solid #fff;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
	color: #fff;
	background-color: #00CCBD !important;

	svg {
		stroke: #fff;
	}
}
  
.actionBtn button:active {
	background-color: #00CCBD !important;
	border: 1px solid #008aaa !important;
	color: #008aaa !important;
  
	svg {
	  stroke: #008aaa !important;
	}
}

.iconBtnsWrapper {
	display: flex;
	justify-content: flex-start;

	.width {
	  width: 10px;
	}
}

.iconBtn {
	padding: 0 10px;
	margin: 0 5px;
	border-radius: 6px;
	border: 1px solid transparent !important;

	&:hover {
		border: 1px solid #00CCBD !important;
		background-color: #00CCBD;
		path {
			stroke: #fff;
		}
	}

	&:active {
		background-color: #00CCBD;
		border: 1px solid #008aaa !important;
		path {
			stroke: #008aaa;
		}
	}
}

.iconBtnTable {
	border-radius: 6px;
    border: 1px solid transparent !important;
    background-color: transparent !important;
    padding: 0 10px;
	margin: 0 5px;

	&:hover {
		border: 1px solid #fff !important;
		path {
			stroke: #fff;
		}
	}

	&:active {
		outline: none !important;
		border: 1px solid #008aaa !important;
		path {
			stroke: #008aaa;
		}
	}

	svg {
		height: 20px;
	}
}
