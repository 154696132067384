.cardWrapper {
    margin: 0 !important;
    padding: 20px 20px 24px 20px;
    box-sizing: border-box;
    border-radius: 6px 6px 0 0;
    background: #00C1D4;
    border: 1px solid rgba(46, 91, 255, 0.08);
    display: flex;
    flex-flow: row wrap;
}

.title1 {
    color: #ffffff;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

.title2 {
    color: #ffffff;
    font-style: normal;
    font-weight: 800;
    font-size: 44px;
    line-height: 40px;
    letter-spacing: 0.06em;
    padding-top: 10px;

    @media screen and (max-width: 575px) {
        padding: 18px 0;
        font-size: 32px !important;
        line-height: 1.2 !important;
    }
}

.dashboard-btn {
    min-width: 235px;
}

.list {
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    color: rgba(42, 43, 45, 0.85);
}

.number {
    color: #00C1D4;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    padding-left: 8px;
    padding-right: 8px;
}

.paymentType {
    font-size: 14px;
}

.dashboard-notifications-table .ant-table-thead > tr >th {
    background-color: #fafafa;
}

.orange {
    color: #FEC63D;
  }
.green {
color: #1DDB47;
}
.red {
color: #F80059;
}

.goToPage {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #008AAA;
    padding-bottom: 20px;
    a {
        color: #008AAA !important;
    }
}

.dashboardListText {
    display: inline;

    @media screen and (max-width: 575px) {
        display: block;
    }
}

.dashboardLogo {
    @media (max-width: 575px) {
        transform: scale(0.8);
    }
}

.dashboardCard .bodyWrapper {
    display: flex;

    @media (max-width: 575px) {
      padding: 18px 0;
    }
}

.dashboardTitlesWrapper {
    @media (max-width: 575px) {
        padding: 0 !important;
    }
}