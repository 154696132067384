html,
body,
#root {
  height: 100%;
}

.colorWeak {
  filter: invert(80%);
}

.ant-layout {
  min-height: 100vh;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //font-family: @font-family;
}

ul,
ol {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:focus-within,
input:-webkit-autofill:active {
  transition: background-color 1s ease-in-out 0s !important;
  -webkit-transition: background-color 1s ease-in-out 0s !important;
  box-shadow: 0 0 0 100px rgba(255, 255, 255, 1) inset !important;
  -webkit-box-shadow: 0 0 0 100px rgba(255, 255, 255, 1) inset !important;
}

/*
input::-webkit-input-placeholder {
	line-height:normal!important;
}

input::-moz-placeholder {
	line-height:normal!important;
}*/
@include media-breakpoint-down(xs) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;
        > span {
          display: block;
        }
      }
    }
  }
}

.ant-pro-sider-logo {
  min-height: 48px;
  padding: 10px 16px;
  margin: 0px 0px 10px 0px;
  border-bottom: 0px solid #007995;
  box-shadow: 0px 1px 6px rgba(0, 21, 41, 0.5);
}
.ant-pro-sider-logo img {
  height: 35px;
}
.ant-pro-sider-logo h1 {
  font-size: 25px;
}

.ant-pro-sider.ant-layout-sider-collapsed .ant-pro-sider-logo {
  padding: 4px 4px;
}


.ant-layout-header {
  //height: 80px !important;
  //line-height: 80px !important;
  background-color: #ffffff !important;
  background: #ffffff !important;
}

.ant-pro-global-header-collapsed-button {
  padding-top: 12px !important;
  color: #008aaa !important;
}

.ant-pro-top-nav-header-logo h1 {
  font-size: 28px;
}

.ant-pro-top-nav-header {
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.0) !important;
}

//Hide Menus and show left full
.ant-pro-top-nav-header-logo {
  width: 100%;
}
.ant-pro-top-nav-header-main-left {
  flex: 1 1 0%;
}
.ant-pro-top-nav-header-menu {
  display: none;
  overflow: hidden;
}
.ant-pro-top-nav-header-main {
  padding: 0px 5% 0px 5% !important;
}

.ant-pro-global-header {
  padding: 0px 5% 0px 5%;
  box-shadow: none;
}

//New design added

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 2px solid #f0f0f0;
}
.ant-divider-inner-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #9199aa;
}

.ant-form-item-control
{
  max-width: 100% !important;
}

.ant-pro-basicLayout-content {
  margin: 0px;
}

// 兼容IE11
@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}

.goBack a {
  position: absolute;
  padding-left: 20px;

  @media (max-width: 575px) {
    position: relative;
    padding-left: 0;
  }
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

.ant-form-item {
  box-sizing: border-box;
  padding: 0;
  color: rgba(42,43,45,.85);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum","tnum";
  vertical-align: top;
}

.ant-row {
  display: flex;
  flex-flow: row wrap;
}

.ant-form-item-control:first-child:not([class^=ant-col-]):not([class*=" ant-col-"]) {
  width: 100%;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
}

.ant-spin-dot-item {
  background-color: #00ccbd;
}

.ant-checkbox-wrapper > span {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #2a2b2d;
}

.formCheckbox > .ant-checkbox {}

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #d1d6da;
  background-color: #ffffff !important;
}

.formCheckbox .ant-checkbox-inner::after {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  top: -1px;
  left: 0px;
  background-image: url("../assets/checkmark.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  background-color: #277fff;
}

.formCheckbox .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: scale(1.1) translateY(1px);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-checkbox-inner:hover {
  background-color: rgba(39, 127, 255, 0.3) !important;
  border: 1px solid #277fff;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #277fff !important;
}