.masterLayout-content {
  padding: 20px;
  background-color:  #F4F6FC;
  width: 100%;
}

.roleError {
  position: fixed;
  z-index: 20000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #008aaa !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
}

.menuDrawer {
  .ant-drawer-content-wrapper {
    width: 240px !important;

    .ant-drawer-wrapper-body .ant-drawer-body {
      padding: 0 !important;
    }
  }
  
  .ant-drawer-header {
    border-top: 1px solid #f0f0f0;
  }

  .callImg {
    display: none;
  }
}

.titleWrapper {
  display: block;
  padding-bottom: 30px;

  h2 {
    margin-bottom: 45px;
    margin-top: 20px;

    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-bottom: 0;
  }

}
