.tableLoanOffersTable .ant-table {
    background-color: transparent;
}

.orange {
    color: #FEC63D;
  }
.green {
color: #1DDB47;
}
.red {
color: #F80059;
}

.loanOffersTableWrapper {
  // width: calc(100vw - 315px);
    
  // @media (max-width: 991px) {
  //     width: 100%;
  // }
}
.table-custom-filter  {
  .ant-table-filter-trigger-container {
    right: unset !important;
    left: 50%;
    transform: translateX(-50%) !important;
  }
}