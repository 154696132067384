.react-datepicker-wrapper {
    width: 100%;
    outline: none;

    // &:focus {
    //     border: 1px solid #00ccbd;
    //     box-shadow: 0 0 black;
    //     outline: none;
    // }
}

.date-picker {
    width: 100%;
    height: 54px;
    border-radius: 6px;
    border: 1px solid #d1d6da;
    outline: none;

    font-size: 16px;
    line-height: 20px;
    color: #2a2b2d;
}

.date-picker-error {
    border: 1px solid #f80059 !important;
}

.date-picker-error-helper {
    color: #f80059;
    font-size: 12px;
    line-height: 15px;
    padding-top: 2px;
    padding-left: 13px;
}

.date-picker.react-datepicker-ignore-onclickoutside {
    border: 1px solid #00ccbd;
    box-shadow: 0 0 black;
    outline: none;

    &:focus {
        border: 1px solid #00ccbd;
        box-shadow: 0 0 black;
        outline: none;
    }
}

.react-datepicker__input-container input{
    padding-left: 10px;
}

.label-datepicker {
    position: relative;
}

.label-datepicker .anticon-calendar {
    position: absolute;
    top: 35%;
    right: 10px;
    z-index: 2;
    
    & svg {
        fill: #d1d6da
    }
}

.label-datepicker {
    &:focus-within {
        & svg {
            fill: #00ccbd
        }
    }
}