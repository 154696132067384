.tableLoanOffersTable .ant-table {
	background-color: transparent;
}

.orange {
	color: #fec63d;
}
.green {
	color: #1ddb47;
}
.red {
	color: #f80059;
}

.export {
	// position: relative;
	&-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: translateX(38px);
		&:hover {
			background-color: #e5e5e5;
			cursor: pointer;
			.export-icon svg {
				fill: #86878b;
			}
		}
	}
	&-icon {
		svg {
			width: 17px;
			height: 17px;
			fill: #bfbfbf;
			transition: fill 0.3s ease;
		}
		&:hover {
			svg {
				fill: #86878b;
			}
			cursor: pointer;
			background-color: #e5e5e5;
		}
		path {
			stroke: #bfbfbf;
		}
	}
}

@media (min-width: 769px) {
	.follow-up {
		&-comment {
			position: absolute;
			background-color: #000000d0;
			padding: 10px;
			border-radius: 3px;
			z-index: 100;
			width: 250px;
			left: 50%;
			transform: translateX(-50%);
			bottom: 120%;
			opacity: 0;
			visibility: hidden;
			transition: all 0.2s ease-in-out;
		}
		&-date {
			cursor: pointer;
			position: relative;
			&:hover .follow-up-comment, &:hover .follow-up-triangle {
				opacity: 1;
				visibility: visible;
				&::after {
					
				}
			}
		}
		&-triangle {
			position: absolute;
			width: 0;
			height: 0;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-top: 7px solid #000000d0;
			background-color: transparent;
			z-index: 101;
			bottom:  calc(120% - 7px);
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
			visibility: hidden;
			transition: all 0.2s ease-in-out;
		}
	}
}

.table-custom-filter .ant-table-filter-trigger-container {
	right: unset !important;
	left: 50%;
	transform: unset;
}
