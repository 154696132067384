.ant-table-wrapper {
    padding: 20px;
  }
  .ant-table-thead > tr > th {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.0113em;
    color: #8798AD;
  }
  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 14px;
    color: rgba(42, 43, 45, 0.85);
  }
  .ant-table-thead > tr > th {
    .ant-checkbox-wrapper {
      display: none;
    }
  }
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    border-bottom: unset;
    // text-align: center;
  }

.ant-table table {
    border-spacing: 0 10px;
    width: 100%;
    text-align: start;
    border-radius: 6px 6px 0 0;
    border-collapse: separate;
}

// .ant-table table

.ant-table {
    width: 100%;
    text-align: left;
    border-radius: 6px 6px 0 0;
    border-collapse: separate;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(42, 43, 45, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    font-size: 12px;
    border-radius: 6px;
    overflow: hidden;
}

.ant-table-tbody {
    background-color: white;
}

.ant-table-thead > tr > th {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.0113em;
    color: #8798AD;
}

.ant-table-thead > tr > th {
    background-color: transparent;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, 
.ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
  // white-space: break-spaces;

  // @media screen and (max-width: 1320px) {
  //   padding: 8px 4px;
  // }
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
  // background: unset; //Change the existing color to `unset`
  background: #00CCBD;
  color: #ffffff;
}

.anticon-caret-up.ant-table-column-sorter-up.active,
.anticon-caret-down.ant-table-column-sorter-down.active {
  color: #00CCBD;
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    margin: 16px 0;
    display: flex;
    justify-content: flex-end;
}

.ant-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(42, 43, 45, 0.85);
    font-size: 12px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
}

.ant-pagination-prev.ant-pagination-disabled {
    cursor: not-allowed;
}

.ant-pagination-item-link {
    display: flex !important;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    justify-content: center !important;
    align-items: center !important;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 6px !important;
    outline: none;
    transition: all 0.3s;
}

.ant-pagination-item {
    display: flex;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    outline: none;
    transition: all 0.3s;
}

.ant-pagination-item-active {
    border-color: #00CCBD;
    color: #00CCBD;
}

.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}


.ant-table {
  display: block;

  @media screen and (max-width: 768px) {
    &-thead {
      display: none;
    }

    &-thead>tr,
    &-tbody>tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      >th,
      >td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;

        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  white-space: normal;

  @media screen and (max-width: 575px) {
    word-break: break-all;
  }
}

.ant-table-pagination {
  flex-wrap: wrap;

  li {
      margin-bottom: 8px;
  }
}

.ant-table-filter-trigger {
  &.active {
    color: #00CCBD;
  }
  .anticon {
    left: 32% !important;
  }
}

.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(42, 43, 45, 0.85);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 012), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.filterDropdown {
  .ant-checkbox-group {
    margin-bottom: 8px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00CCBD !important;
    border-color: #00CCBD !important;
  }
  
}


.filtersCheckbox {
  .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9 !important;
    border-radius: 6px;
    border-collapse: separate;
    transition: all .3s;

    &:hover {
      border-color: #00ccbd !important;
    }
  }

  .ant-checkbox:hover {
    .ant-checkbox-inner {
      border-color: #00ccbd !important;
      outline: none;
    }
  }

  .ant-checkbox-wrapper span {
    font-size: 12px !important;
    font-variant: tabular-nums !important;
    font-family: 'Mulish', serif !important;
  }
}

.filterBtn {
  width: 90px;
  height: 40px;
  border-radius: 6px !important;
  padding: 1.6px 7px;
  font-size: 12px;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  user-select: none;
  touch-action: manipulation;
  outline: 0;

  span {
    vertical-align: middle;
  }

  svg {
    width: 12px;
    height: 12px;
  }
}

.filterRtnSearch {
  background-color: #00CCBD;
  border-color: #00CCBD;

  &:hover {
    color: #fff;
    background: #23d9c7;
    border-color: #23d9c7;
  }
}

.filterRtnReset {
  border: 1px solid rgb(217, 217, 217);
  color: rgba(0, 0, 0, 0.85);

  &:hover {
    color: #23d9c7;
    background: #fff;
    border-color: #23d9c7;
  }
}

